import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/documentation/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/documentation/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/documentation/update',
        method: 'POST',
        data
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/documentation/delete',
        method: 'GET',
        params
    });
}

// 详情
export function Detail(params) {
    return request({
        url: '/admin/documentation/detail',
        method: 'GET',
        params
    });
}

